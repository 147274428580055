import { db, getUserPlanData, analytics } from "../services/firebase";
import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  Timestamp,
  query,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { auth, logout } from "../services/firebase";
import DashboardHeader from "../components/dashboardHeader";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { onAuthStateChanged } from "firebase/auth";
import MarketingNavbar from "../components/marketingNav";
import LoadingSpinner from "../components/loadingSpinner";
import NoUploadsYet from "../components/elements/noUploadsYet";
import ProjectCard from "../components/elements/drillCard.tsx";
import StorageBanner from "../components/elements/storageBanner.tsx";
import UpgradeModal from "../components/elements/upgradeModal.tsx";
import { toast } from "react-hot-toast";
import SearchAndFilter from "../components/elements/searchAndFilter.tsx";
import { PlusCircle, Trash2 } from "lucide-react";
import { logEvent } from "firebase/analytics";
import Footer from "../components/footer";

export default function MyDesigns() {
  const [drills, setDrills] = useState([]);
  const [sharedWithMeDrills, setSharedWithMeDrills] = useState([]);

  const [loading, setLoading] = useState(true);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [newDrillName, setNewDrillName] = useState("");
  const [userData, setUserData] = useState(null); // Store user data
  const [uploadingAvailable, setUploadingAvailable] = useState(false);
  const [isPlanInactive, setIsPlanInactive] = useState(null); // Store user data
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [storageLimit, setStorageLimit] = useState(5);
  const navigate = useNavigate();
  const [designTab, setDesignTab] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("updated-desc");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDrill, setSelectedDrill] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoading(true);
        fetchDrills(auth.currentUser.uid); // Pass the user ID
        fetchUserData();
        setLoading(false);
      } else {
        // alert("No user found, please log in");
        navigate("/login"); // Or redirect to login
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth, navigate]); // Include `navigate` in dependencies

  useEffect(() => {
    // Only check the upload status when both userData and drills are available
    if (userData && drills.length >= 0) {
      checkUploadStatus();
    }
  }, [userData, drills]);

  const fetchDrills = async (userId) => {
    if (!userId) return;
    setLoading(true);
    try {
      // Create a proper query to filter by user ID
      const designsQuery = query(
        collection(db, "designs"),
        where("createdBy", "==", userId)
      );

      const querySnapshot = await getDocs(designsQuery);
      const drillList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDrills(drillList);

      const designsRef = collection(db, "designs");
      console.log("ID", userId);

      const sharedQuery = query(
        designsRef,
        where("collaboratorIds", "array-contains", {
          uid: userId,
        })
      );

      const sharedQuerySnapshot = await getDocs(sharedQuery);
      const sharedDrillList = sharedQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("SHARED", sharedDrillList);
      setSharedWithMeDrills(sharedDrillList);
    } catch (error) {
      console.error("Error fetching drills:", error);
    }
    setLoading(false);
  };

  const fetchUserData = async () => {
    setLoading(true);
    // console.log(auth.currentUser.uid);
    try {
      // Query the "users" collection to find the document with the matching uid
      const q = query(
        collection(db, "users"),
        where("uid", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If we found a matching document, set the user data
        const userDoc = querySnapshot.docs[0]; // Assuming there's only one document per uid
        setUserData({ id: userDoc.id, ...userDoc.data() });
      } else {
        console.error("No user data found for the current UID");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
    setLoading(false);
  };
  const duplicateDrill = async (drillId) => {
    // if (!selectedDrill) return;
    try {
      const drillRef = doc(db, "designs", drillId);
      const drillSnapshot = await getDoc(drillRef);

      if (drillSnapshot.exists()) {
        const drillData = drillSnapshot.data();

        // Remove any fields that should not be duplicated, e.g., timestamps or unique identifiers
        const { createdAt, ...dataToDuplicate } = drillData;

        // Add a new document with duplicated data
        const newDrillRef = await addDoc(collection(db, "designs"), {
          ...dataToDuplicate,
          title: `${dataToDuplicate.title} (Copy)`, // Modify title to indicate a duplicate
          createdAt: new Date(),
        });

        console.log("Drill duplicated with ID: ", newDrillRef.id);
        setSelectedDrill(null);
        fetchDrills(auth.currentUser.uid); // Pass the user IDx
      } else {
        console.error("Drill not found!");
      }
    } catch (error) {
      console.error("Error duplicating drill: ", error);
    }
  };
  const handleDeleteDrill = async () => {
    if (!selectedDrill) return;

    try {
      // Delete the drill from Firestore
      await deleteDoc(doc(db, "designs", selectedDrill.id));

      // Update the local state to reflect the deletion
      setDrills((prevDrills) =>
        prevDrills.filter((drill) => drill.id !== selectedDrill.id)
      );

      // Close the modal
      setShowDeleteModal(false);
      setSelectedDrill(null);
    } catch (error) {
      console.error("Error deleting drill:", error);
    }
  };
  const createNewDrill = async () => {
    // if (newDrillName.trim() === "") return;
    setLoading(true);
    const newDrill = {
      title: "Untitled Drill Design",
      createdAt: Timestamp.now(),
      createdBy: auth.currentUser.uid,
      // Add any other initial drill properties here
    };

    try {
      const docRef = await addDoc(collection(db, "designs"), newDrill);
      logEvent(analytics, "create_new_drill_document", {
        drill_title: newDrill.title,
        created_by: auth.currentUser.uid,
        created_at: Timestamp.now().toDate().toISOString(), // Log as ISO string for consistency
        document_id: docRef.id,
        user_plan: "pro", // Replace with dynamic plan fetching if available
        device_type: navigator.userAgent.includes("Mobi")
          ? "mobile"
          : "desktop",
        collaborators: 0, // Assuming no collaborators at creation
        drill_type: "marching_band", // Example default value
      });
      setNewDrillName("");
      fetchDrills(auth.currentUser.uid); // Pass the user ID
      // After successfully creating the document, navigate to its page
      navigate(`/designs/${docRef.id}`); // Navigate to the page
    } catch (error) {
      console.error("Error adding document: ", error);
    }
    await delay(900);

    setLoading(false);
  };

  const checkUploadStatus = async () => {
    setLoading(true);
    setUploadingAvailable(false);

    try {
      const planData = await getUserPlanData(auth.currentUser.uid);
      console.log(planData);
      if (!planData || !userData) {
        console.error("Plan data or user data is missing");
        setLoading(false);
        return;
      }

      const { planKey, planActive } = planData;
      if (planKey === null && drills.length < 5) {
        setIsPlanInactive(false);
        setUploadingAvailable(true);
        setStorageLimit(50);

        return;
      }
      if (planActive === false) {
        setIsPlanInactive(true);
        setUploadingAvailable(false);
        return;
      }

      if (planActive === true) {
        setUploadingAvailable(true);
        setStorageLimit(10000);
      } else if (planKey === "free") setStorageLimit(50);
    } catch (error) {
      console.error("Error checking upload status:", error);
    } finally {
      await delay(900);
      setLoading(false);
    }
  };
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  // console.log(oneWeekAgo);

  const filterAndSortDrills = (drills) => {
    return drills
      .filter((drill) =>
        drill.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => {
        switch (sortBy) {
          case "updated-desc":
            return b.updated?.toDate() - a.updated?.toDate();
          case "updated-asc":
            return a.updated?.toDate() - b.updated?.toDate();
          case "title-asc":
            return a.title.localeCompare(b.title);
          case "title-desc":
            return b.title.localeCompare(a.title);
          default:
            return 0;
        }
      });
  };

  if (loading)
    return (
      <>
        <MarketingNavbar />
        <LoadingSpinner />
      </>
    );
  return (
    <div className="">
      <Helmet>
        <title>Drill Designs | DrillFlo</title>
      </Helmet>
      <MarketingNavbar />

      <header className="bg-gradient-to-r from-blue-500 to-purple-600">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
          <h1 className="text-2xl font-bold text-white sm:text-3xl">Designs</h1>
        </div>
      </header>

      <StorageBanner
        currentUsage={drills.length}
        storageLimit={storageLimit}
        onUpgradeClick={() => setShowUpgradeModal(true)}
      />

      <div className="py-8">
        <nav className="border-b bg-white">
          <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
            <div className="flex gap-8 py-4">
              <button
                onClick={() => setDesignTab(1)}
                className={`${
                  designTab === 1
                    ? "border-b-2 border-blue-500 text-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                } font-medium`}
              >
                My Designs
              </button>
              <button
                onClick={() => setDesignTab(2)}
                className={`${
                  designTab === 2
                    ? "border-b-2 border-blue-500 text-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                } font-medium`}
              >
                Shared With Me
              </button>
            </div>
          </div>
        </nav>
      </div>
      <SearchAndFilter
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
      {designTab === 1 && (
        <>
          {isPlanInactive && (
            <span className="inline-flex items-center justify-center rounded-full bg-red-100  mx-24 px-2.5 py-0.5 text-red-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="-ms-1 me-1.5 size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>

              <p className="whitespace-nowrap text-sm">
                Your Subscription is Inactive. Please visit{" "}
                <Link to="/pricing" className="underline">
                  Pricing
                </Link>{" "}
                to upgrade
              </p>
            </span>
          )}
          <h2 className="font-extrabold md:text-3xl pb-4 mt-8 bg-gradient-to-r from-blue-500 to-purple-500  bg-clip-text text-transparent px-24  ">
            My Designs
          </h2>
          {uploadingAvailable === true && drills.length == 0 && (
            <>
              <NoUploadsYet createNewDrill={createNewDrill} />
            </>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-8 max-w-5xl m-auto">
            {uploadingAvailable === false && (
              <div
                onClick={() => setShowUpgradeModal(true)}
                className=" cursor-pointer block rounded-lg p-4 shadow-sm text-center m-auto shadow-indigo-100 hover:bg-gray-200 transition-all ease-in-out bg-gradient-to-r from-blue-500 to-purple-600  text-white"
              >
                <h3 className="font-bold text-lg">No More Uploads</h3>
                <span>Upgrade to unlock more</span>
              </div>
            )}
            {uploadingAvailable && drills.length > 0 && (
              <button
                onClick={createNewDrill}
                className="flex flex-col items-center justify-center p-6 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 hover:bg-blue-50 transition-all group"
              >
                <PlusCircle className="h-12 w-12 text-gray-400 group-hover:text-blue-500" />
                <span className="mt-2 font-medium text-gray-600 group-hover:text-blue-500">
                  New Design
                </span>
              </button>
            )}
            {filterAndSortDrills(drills).map((drill) => (
              <>
                <ProjectCard
                  title={drill.title}
                  link={drill.id}
                  ownedDrill={true}
                  id={drill.id}
                  setSelectedDrill={() => setSelectedDrill(drill)}
                  setShowDeleteModal={setShowDeleteModal}
                  lastUpdated={(() => {
                    const updatedDate = drill.updated.toDate();
                    const today = new Date();
                    const yesterday = new Date();
                    yesterday.setDate(today.getDate() - 1);

                    // Check if the date matches today or yesterday
                    if (updatedDate.toDateString() === today.toDateString()) {
                      return "Today";
                    } else if (
                      updatedDate.toDateString() === yesterday.toDateString()
                    ) {
                      return "Yesterday";
                    } else if (updatedDate > oneWeekAgo) {
                      return updatedDate.toLocaleDateString(undefined, {
                        weekday: "long",
                      });
                    } else {
                      return updatedDate.toLocaleDateString();
                    }
                  })()}
                  thumbnail={
                    drill.snapshotURL ||
                    "https://media.sweetwater.com/m/products/image/f01cbad910PNt0umUIZ7D4QsGUaGKLoT3R5pDix9.wm-lw.png?quality=82&width=750&ha=f01cbad910f6d506"
                  }
                  favorite
                  status=""
                  duplicateDrill={() => duplicateDrill(drill.id)} // Pass the duplicate function as a prop
                />
              </>
            ))}
          </div>{" "}
        </>
      )}
      {designTab === 2 && (
        <>
          {isPlanInactive && (
            <span className="inline-flex items-center justify-center rounded-full bg-red-100  mx-24 px-2.5 py-0.5 text-red-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="-ms-1 me-1.5 size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>

              <p className="whitespace-nowrap text-sm">
                Your Subscription is Inactive. Please visit{" "}
                <Link to="/my-account" className="underline">
                  My Account
                </Link>
              </p>
            </span>
          )}
          <h2 className="font-extrabold md:text-3xl pb-4 mt-8 bg-gradient-to-r from-blue-500 to-purple-500  bg-clip-text text-transparent px-24  ">
            Shared with Me
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-8 ">
            {filterAndSortDrills(sharedWithMeDrills).map((drill) => (
              <>
                <ProjectCard
                  title={drill.title}
                  link={drill.id}
                  ownedDrill={false}
                  lastUpdated={(() => {
                    const updatedDate = drill.updated.toDate();
                    const today = new Date();
                    const yesterday = new Date();
                    yesterday.setDate(today.getDate() - 1);

                    // Check if the date matches today or yesterday
                    if (updatedDate.toDateString() === today.toDateString()) {
                      return "Today";
                    } else if (
                      updatedDate.toDateString() === yesterday.toDateString()
                    ) {
                      return "Yesterday";
                    } else if (updatedDate > oneWeekAgo) {
                      return updatedDate.toLocaleDateString(undefined, {
                        weekday: "long",
                      });
                    } else {
                      return updatedDate.toLocaleDateString();
                    }
                  })()}
                  thumbnail={
                    drill.snapshotURL ||
                    "https://media.sweetwater.com/m/products/image/f01cbad910PNt0umUIZ7D4QsGUaGKLoT3R5pDix9.wm-lw.png?quality=82&width=750&ha=f01cbad910f6d506"
                  }
                  favorite
                  status=""
                />
              </>
            ))}
            {sharedWithMeDrills.length < 1 && (
              <h3 className="text-center font-bold text-xl m-auto">
                No drills available
              </h3>
            )}
          </div>
        </>
      )}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-md">
            <Trash2 className="h-8 w-8 m-auto" />
            <h2 className="text-lg font-bold"> Confirm Deletion</h2>
            <p className=" max-w-prose py-4">
              Are you sure you want to delete this design,{" "}
              <b> {selectedDrill.title}</b>? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                No, Cancel
              </button>
              <button
                onClick={handleDeleteDrill}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Yes, I'm sure
              </button>
            </div>
          </div>
        </div>
      )}

      <UpgradeModal
        isOpen={showUpgradeModal}
        userData={auth.currentUser.uid}
        onClose={() => setShowUpgradeModal(false)}
        currentUsage={drills.length}
        storageLimit={storageLimit}
      />
      <div className="py-8" />
      <Footer />
    </div>
  );
}
