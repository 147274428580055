import React, { useState, useRef, useEffect } from "react";
import {
  MoreVertical,
  Clock,
  Star,
  Pencil,
  Copy,
  Archive,
  Trash2,
} from "lucide-react";

interface ProjectCardProps {
  id: string;
  title: string;
  lastUpdated: string;
  thumbnail: string;
  favorite: boolean;
  status: string;
  link: string;
  ownedDrill: boolean;
  setSelectedDrill: () => void;
  duplicateDrill: () => void;
  setShowDeleteModal: (show: boolean) => void;
}

export default function ProjectCard({
  id,
  title,
  lastUpdated,
  thumbnail,
  favorite,
  status,
  link,
  ownedDrill,
  setSelectedDrill,
  duplicateDrill,
  setShowDeleteModal,
}: ProjectCardProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [isFavorite, setIsFavorite] = useState(favorite);
  const cardRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        menuButtonRef.current &&
        !menuButtonRef.current.contains(event.target as Node)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "complete":
        return "bg-green-100 text-green-800";
      case "in progress":
        return "bg-blue-100 text-blue-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div ref={cardRef} className="relative">
      <a
        className="group block bg-white rounded-xl shadow-sm border border-gray-200 hover:shadow-md transition-all"
        href={`/designs/${link}`}
      >
        <div className="aspect-video relative overflow-hidden rounded-t-xl">
          <img
            src={thumbnail}
            alt={title}
            className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
          />
          <div className="absolute inset-0 bg-black/0 group-hover:bg-black/20 transition-colors">
            {ownedDrill == true && (
              <div className="absolute top-2 right-2">
                <button
                  ref={menuButtonRef}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowMenu(!showMenu);
                  }}
                  className="p-1.5 bg-white/90 rounded-full hover:bg-white transition-colors"
                >
                  <MoreVertical className="h-4 w-4 text-gray-600" />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="p-4">
          <div className="flex items-center justify-between mb-2">
            <h3 className="font-semibold text-gray-900">{title}</h3>
            <span
              className={`text-xs px-2 py-1 rounded-full ${getStatusColor(
                status
              )}`}
            >
              {status}
            </span>
          </div>
          <div className="flex items-center text-sm text-gray-500">
            <Clock className="h-4 w-4 mr-1" />
            Last updated {lastUpdated}
          </div>
        </div>
      </a>

      {/* Dropdown Menu */}
      {showMenu && (
        <div
          ref={menuRef}
          className="absolute z-50 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1"
          style={{
            top: "2.5rem",
            right: "0.5rem",
          }}
        >
          {/* <button className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2">
            <Pencil className="h-4 w-4" /> Edit
          </button> */}
          <button
            className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2"
            onClick={() => {
              setSelectedDrill();

              duplicateDrill(id);
            }}
          >
            <Copy className="h-4 w-4" /> Duplicate
          </button>

          <button
            className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-red-50 flex items-center gap-2"
            onClick={() => {
              setSelectedDrill();

              setShowDeleteModal(true);
            }}
          >
            <Trash2 className="h-4 w-4" /> Delete
          </button>
        </div>
      )}
    </div>
  );
}
